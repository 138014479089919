var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-white d-flex mb-3 p-2"},[_c('TButton',{staticClass:"ml-auto",attrs:{"content":"Remove empty pallets from stock","icon":"cil-trash","color":"danger","disabled":_vm.removeEmptyPalletLoading},on:{"click":_vm.clearEmptyPallets}})],1),_c('CCard',[_c('CCardHeader',{staticClass:"d-flex flex-wrap align-items-center"},[_c('TMessage',{attrs:{"content":"Pallets have not been stored","bold":"","color":"muted"}})],1),_c('CCardBody',{staticClass:"scroll-x"},[_c('div',{staticClass:"d-inline-flex"},[(!_vm.pallets.length)?_c('TMessageNotFound'):_vm._l((_vm.pallets),function(pallet){return _c('CCard',{key:pallet.id,staticClass:"mx-2 my-2"},[_c('CCardBody',[_c('SCardPallet',{attrs:{"pallet":pallet,"refLink":"","tiny":"","showCountBox":""}})],1)],1)}),_c('SButtonLoadmore',{staticClass:"my-auto mx-2",attrs:{"store":"warehouse.pallets"}})],2)])],1),_c('TSpinner',{attrs:{"loading":_vm.shelve_loading}}),_vm._l((_vm.shelves),function(shelve){return _c('CCard',{key:shelve.id},[_c('CCardHeader',[_c('TMessage',{attrs:{"content":"Shelve"},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(shelve.name))]),_c('i',[_vm._v(" (F:"+_vm._s(shelve.floor)+" - C:"+_vm._s(shelve.column)+" - R:"+_vm._s(shelve.row)+") ")])]},proxy:true}],null,true)})],1),_c('CCardBody',{staticClass:"scroll-x"},_vm._l((_vm.getMaxFloor(shelve)),function(floor){return _c('div',{key:floor,staticClass:"mb-3"},_vm._l((_vm.getMaxColumn(shelve, floor)),function(column){return _c('div',{key:column,staticClass:"d-inline-flex"},_vm._l((_vm.getMapLocations(
              shelve,
              floor,
              column
            )),function(location,index){return _c('CCard',{key:location.id + '-' + index,staticClass:"mb-1 mr-1",staticStyle:{"width":"115px"},attrs:{"borderColor":_vm.getColor(location)}},[(location.id)?_c('CCardHeader',{staticClass:"d-flex p-0 px-2"},[_c('i',[_vm._v(" "+_vm._s(shelve.name)+_vm._s(location.row)+"-"+_vm._s(location.floor)+" ")]),(location.pallet)?_c('CBadge',{staticClass:"ml-auto my-auto",staticStyle:{"font-size":"85%"},attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.getTotalQuantityBoxPallet(location.pallet.pivot_boxes))+" ")]):_vm._e()],1):_vm._e(),(location.id)?_c('CCardBody',{staticClass:"py-2 px-1"},[(location.pallet)?_c('TLink',{attrs:{"content":location.pallet.id,"to":_vm.lodash.getReferenceLink('assemble-to-stock.pallet', location.pallet.id),"messageOptions":{
                  noTranslate: true,
                  bold: true,
                  alignment: 'center',
                },"removable":""},on:{"click-remove":function($event){return _vm.removePalletFromLocation(location)}}}):_c('TInputText',{attrs:{"title":_vm.$t('Add pallet')},on:{"update:value":function($event){return _vm.addPalletToLocation(location, $event)}}})],1):_vm._e(),(location.id && location.pallet)?_c('CCardFooter',{staticClass:"p-0"},[_c('CProgress',{staticStyle:{"height":"5px"},attrs:{"value":_vm.getVolume(location),"max":_vm.getMaxVolume(location),"size":"sm"}})],1):_vm._e()],1)}),1)}),0)}),0)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }